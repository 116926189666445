export const radioComponentTheme = {
  Radio: {
    variants: {
      brand: {
        control: {
          // bg: 'base.2',
          // borderColor: 'base.5',
          // m: 'spacing-02',
          // width: '16px',
          // height: '16px',
          // _before: {
          //   width: '4px',
          //   height: '4px',
          //   borderRadius: '50%',
          //   bg: 'transparent'
          // },
          // _invalid: {
          //   borderColor: 'ruby.6'
          // },
          // _hover: {
          //   bg: 'base.3',
          //   borderColor: 'base.5',
          //   _invalid: {
          //     borderColor: 'ruby.6'
          //   }
          // },
          // _active: {
          //   bg: 'blueGreen.1',
          //   borderColor: 'blueGreen.5',
          //   _invalid: {
          //     borderColor: 'ruby.6'
          //   }
          // },
          // _focus: {
          //   bg: 'blueGreen.2',
          //   borderColor: 'blueGreen.4',
          //   _before: {
          //     bg: 'blueGreen.2'
          //   }
          // },
          // _focusVisible: {
          //   boxShadow: 'unset',
          //   outline: 'unset'
          // },
          // _disabled: {
          //   bg: 'base.3',
          //   borderColor: 'base.3',
          //   _hover: {
          //     bg: 'base.3',
          //     borderColor: 'base.3'
          //   }
          // },
          // _checked: {
          //   bg: 'blueGreen.10',
          //   borderColor: 'blueGreen.10',
          //   _before: {
          //     bg: 'base.1',
          //     width: '4px',
          //     height: '4px',
          //     borderRadius: '50%'
          //   },
          //   _invalid: {
          //     borderColor: 'ruby.6'
          //   },
          //   _hover: {
          //     bg: 'blueGreen.7',
          //     borderColor: 'blueGreen.7',
          //     _invalid: {
          //       borderColor: 'ruby.6'
          //     }
          //   },
          //   _active: {
          //     bg: 'blueGreen.9',
          //     borderColor: 'blueGreen.9',
          //     _invalid: {
          //       borderColor: 'ruby.6'
          //     }
          //   },
          //   _focus: {
          //     bg: 'blueGreen.10',
          //     borderColor: 'blueGreen.4'
          //   },
          //   _focusVisible: {
          //     boxShadow: 'unset',
          //     outline: 'unset'
          //   },
          //   _disabled: {
          //     bg: 'base.3',
          //     borderColor: 'base.3',
          //     _before: {
          //       bg: 'base.7'
          //     },
          //     _hover: {
          //       bg: 'base.3',
          //       borderColor: 'base.3',
          //       _before: {
          //         bg: 'base.7'
          //       }
          //     }
          //   }
          // }
        },
        label: {
          // color: 'text.darkPrimary',
          // fontSize: 'label-base',
          // lineHeight: 'label-base',
          // fontWeight: 'medium',
          // fontFamily: 'heading',
          // ml: 'spacing-02'
        }
      }
    },
    defaultProps: {
      variant: 'brand'
    }
  }
}
