import { Box, Flex, Img, RadioGroup, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Button, NewBadge, Radio, SoonBadge, Text } from '@ui'
import {
  PaymentMethod,
  type PaymentMethodCardProps,
  type PaymentMethodViewProps
} from '@widgets/BookingFlow/ui/PaymentModal/types.ts'
import { useState } from 'react'

export const PaymentMethodView = ({ onSubmit }: PaymentMethodViewProps) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>(
    PaymentMethod.bankCard
  )

  const handleMethodChange = (method: PaymentMethod) => {
    setSelectedMethod(method)
  }

  const handleContinue = async () => {
    try {
      setIsLoading(true)
      await onSubmit(selectedMethod)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Flex direction="column" justify="space-between" width="full" height="full">
      <Flex
        width="full"
        py="6"
        px="4"
        overflowY="scroll"
        maxHeight={{ base: 'calc(100dvh - 160px)', md: 'calc(464px - 160px)' }}
        direction="column"
        maxWidth="402px"
        mx="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '0'
          }
        }}
      >
        <RadioGroup value={selectedMethod} onChange={handleMethodChange}>
          <VStack align="stretch">
            <PaymentMethodCard
              label="bankCard"
              imgSrc="/assets/images/bank-card.svg"
              imgAlt="bank card"
              radioProps={{
                value: PaymentMethod.bankCard,
                onChange: () => handleMethodChange(PaymentMethod.bankCard)
              }}
              isBorder
            />

            <PaymentMethodCard
              label="AmeriaPay"
              imgSrc="/assets/images/ameria-pay.svg"
              imgAlt="ameriaPay"
              radioProps={{
                value: PaymentMethod.ameriaPay,
                onChange: () => handleMethodChange(PaymentMethod.ameriaPay)
              }}
              labelSuffix={<NewBadge mt="1" />}
              isBorder
            />

            <PaymentMethodCard
              label="onlineLoan"
              imgSrc="/assets/images/online-loan.svg"
              imgAlt="online loan"
              labelSuffix={<SoonBadge mt="1" />}
              isDisabled
            />
          </VStack>
        </RadioGroup>
      </Flex>

      <Box
        p="4"
        width="full"
        borderTop="1px solid"
        borderColor="gray.100"
        backgroundColor="white"
        mt="auto"
      >
        <Button
          variant="solid-blue"
          type="submit"
          size="lg"
          width="full"
          onClick={handleContinue}
          isLoading={isLoading}
        >
          {t`pay`}
        </Button>
      </Box>
    </Flex>
  )
}

const PaymentMethodCard = ({
  label,
  imgSrc,
  imgAlt,
  radioProps,
  isBorder,
  isDisabled,
  labelSuffix
}: PaymentMethodCardProps) => {
  const { t } = useTranslation()

  const handleCardClick = () => {
    if (!isDisabled && radioProps?.onChange) {
      radioProps.onChange({ target: { value: radioProps.value } } as any)
    }
  }

  return (
    <Flex
      align="center"
      justify="space-between"
      py="3"
      borderBottom={isBorder ? '1px solid' : 'none'}
      borderColor="gray.300"
      opacity={isDisabled ? '0.4' : '1'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={handleCardClick}
    >
      <Flex align="center">
        <Img src={imgSrc} alt={imgAlt} height="40px" width="40px" />
        <Text size="sm" ml="3" mr={labelSuffix ? '1' : '0'}>
          {t(label)}
        </Text>

        {labelSuffix}
      </Flex>

      <Radio
        cursor={isDisabled ? 'not-allowed !important' : 'pointer'}
        size="lg"
        {...(radioProps || {})}
      />
    </Flex>
  )
}
