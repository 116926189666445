import { useUserContext } from '@entities/user'
import {
  type PackageEntity,
  type PaymentSystem,
  useBookPackage,
  useCreateRequest,
  useUpdateRequest
} from '@entities/package'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { PaymentModalView } from '../ui/PaymentModal/types.ts'
import { isMobile } from 'react-device-detect'

import { type Travelers } from '@widgets/TravelersModal/ui/types.ts'
import { useTranslation } from 'react-i18next'
import { LANGUAGE_NAME_MAP, type LanguageName } from '@shared/model'

export const useBookingFlow = ({
  packageDetails,
  initialView,
  onClose,
  isOpen,
  childrenAges,
  defaultTravelers
}: useBookingFlowProps) => {
  const { user } = useUserContext()
  const { i18n } = useTranslation()
  const { mutateAsync: createRequestAsync } = useCreateRequest()
  const { mutateAsync: updateRequestAsync } = useUpdateRequest()
  const { mutateAsync: bookPackageAsync } = useBookPackage()
  const requestIdRef = useRef<number | null>(null)
  const [modalView, setModalView] = useState('')
  const [paymentModalView, setPaymentModalView] =
    useState<PaymentModalView>('paymentForm')
  const [travelers, setTravelers] = useState<any>({ adults: [], children: [] })

  const [notesJson, setNotesJson] = useState('')
  // const travelersRef = useRef<ITraveler[]>([])
  //

  useEffect(() => {
    setModalView(initialView)
  }, [initialView, isOpen])

  const openTravelersModal = () => {
    setModalView('travelers')
  }

  const closeModal = () => {
    setModalView('')
    onClose && onClose()
    setTravelers({ adults: [], children: [] })
    requestIdRef.current = null
    setPaymentModalView('paymentForm')
  }

  const onTravelersModalSuccess = (travelers: Travelers) => {
    setTravelers(travelers)
    setModalView('payment')
  }

  const openPaymentModal = () => {
    setModalView('payment')
  }

  const onPaymentModalSuccess = useCallback(
    async (paymentAmount: number, paymentSystem: PaymentSystem) => {
      if (!packageDetails) {
        return
      }

      try {
        const bookInput: any = {
          cityId: packageDetails.city.id,
          price: packageDetails.price,
          hotelId: packageDetails.hotel.id,
          travelAgencyId: packageDetails.travelAgency.id,
          offerId: packageDetails.offerId,
          roomType: packageDetails.roomType,
          email: user?.email || '',
          notes: notesJson,
          phoneNumber: user?.phoneNumber || '',
          amountToBePaid: +paymentAmount,
          usdRate: packageDetails.usdRate,
          travelers: [...travelers.adults, ...travelers.children],
          paymentSystem
        }

        if (packageDetails.destinationFlight?.departureDate) {
          bookInput.startDate = packageDetails.destinationFlight.departureDate
          bookInput.endDate = packageDetails.returnFlight.departureDate
          bookInput.destinationFlightId = packageDetails.destinationFlight.id
          bookInput.returnFlightId = packageDetails.returnFlight.id
          bookInput.bookingType = 1
        } else {
          bookInput.startDate = packageDetails.checkin
          bookInput.endDate = packageDetails.checkout
          bookInput.bookingType = 2
        }

        const bookResponse = await bookPackageAsync(bookInput)

        if (paymentSystem === ('VPos' as PaymentSystem.VPos)) {
          window.location.href =
            bookResponse.bookingPaymentUrl +
            `&lang=${LANGUAGE_NAME_MAP[i18n.language as LanguageName]}`

          return
        } else if (
          paymentSystem === ('MyAmeriaPay' as PaymentSystem.MyAmeriaPay)
        ) {
          if (isMobile) {
            window.location.href = bookResponse.bookingPaymentUrl

            return
          }

          return bookResponse.bookingPaymentUrl
        }
      } catch (e) {
        setPaymentModalView('paymentError')
      }
    },
    [packageDetails?.offerId, travelers, notesJson]
  )

  useEffect(() => {
    if (!packageDetails?.offerId) {
      return
    }

    if (defaultTravelers) {
      const filledTravelers = {
        adults: [
          ...defaultTravelers.adults,
          ...Array(
            packageDetails.adultTravelers -
              (defaultTravelers.adults.length || 0)
          ).fill({
            firstName: '',
            lastName: '',
            dateOfBirth: ''
          })
        ],
        children: [
          ...defaultTravelers.children,
          ...Array(
            packageDetails?.childrenTravelers +
              packageDetails?.infantTravelers -
              (defaultTravelers.children.length || 0)
          ).fill({
            firstName: '',
            lastName: '',
            dateOfBirth: ''
          })
        ]
      }

      setTravelers(filledTravelers)
    } else if (user?.firstName) {
      setTravelers((prevState: any) => ({
        adults: [
          {
            firstName: user.firstName,
            lastName: user.lastName
          },
          ...Array(packageDetails.adultTravelers - 1).fill({
            firstName: '',
            lastName: '',
            dateOfBirth: ''
          })
        ],
        children: Array(
          packageDetails.childrenTravelers + packageDetails.infantTravelers
        ).fill({
          firstName: '',
          lastName: '',
          dateOfBirth: ''
        })
      }))
    }
  }, [
    user?.id,
    packageDetails?.adultTravelers,
    packageDetails?.childrenTravelers,
    packageDetails?.infantTravelers,
    isOpen,
    defaultTravelers
  ])

  // draft request sync

  const handleTravelersChange = (data: any) => {
    console.log('handleTravelersChange : ', data)

    return null
  }

  return {
    openTravelersModal,
    onTravelersModalSuccess,
    openPaymentModal,
    onPaymentModalSuccess,
    paymentModalView,
    travelers,
    modalView,
    closeModal,
    handleTravelersChange
  }
}

type useBookingFlowProps = {
  packageDetails?: PackageEntity | null
  initialView: 'travelers' | 'payment'
  onClose?: () => void
  isOpen?: boolean
  requestId?: number
  childrenAges?: number[]
  defaultTravelers?: Travelers
}
